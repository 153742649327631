import React from 'react';
import "../../App.css";

export default function AboutMe() {
    
    return <h1 className="ph-text f-25">
        
        Why Ayam Cemani Chickens?<br></br><br></br>

        Ayam Cemani chickens are a rare and exotic breed known for their striking black feathers, black skin, and even black internal organs. Originating from Indonesia, these chickens are highly sought after for their unique appearance and cultural significance. As a result, there has been a growing interest in breeding and raising Ayam Cemani chickens, leading to the emergence of Ayam Cemani chicken breeders around the world.<br></br>

Ayam Cemani chicken breeders play a crucial role in preserving and promoting this rare breed. They are responsible for carefully selecting and breeding Ayam Cemani chickens to maintain the breed's distinct characteristics and genetic purity. Additionally, breeders often provide valuable information and resources to help others successfully raise and care for Ayam Cemani chickens, contributing to the overall preservation and appreciation of this unique breed. In this article, we will explore the world of Ayam Cemani chicken breeders, their role in the conservation of this rare breed, and the growing interest in raising these unique chickens.
    </h1>;
    
    
}