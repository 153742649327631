import React from "react";
import '../../App.css';

export default function Blogs() {
    return <h1 className="ph-text">Same day, different eggs.<br></br>
    <i className=" fa-solid fa-egg"></i><div className='pad-30' />
    <i className=" fa-solid fa-egg"></i><div className='pad-30' />
    <i className=" fa-solid fa-egg"></i><div className='pad-30' />
    
    </h1>
}